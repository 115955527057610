export default function (instance) {
    return {
        getItems(payload) {
            return instance.get('/User/' + payload.userId);
        },
        addItems(payload) {
            return instance.post('/User/AddCatalogs', payload)
        },
        deleteItems(payload) {
            return instance.post('/User/DeleteCatalogs', payload)
        },
        copyItems(payload) {
            return instance.post('/User/CopyCatalogs', payload)
        },
    }
}