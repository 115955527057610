<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="loader"> </v-skeleton-loader>
    <div v-if="!loader">
      <div v-if="viewGroupData.succeeded">
        <v-tabs grow color="teal" v-model="tab">
          <v-tab>Отчет</v-tab>
          <v-tab v-if="viewGroupData.data.marketPharms.length > 0">Аптеки</v-tab>
        </v-tabs>
        <div v-if="tab == null || tab == 0">
          <v-row>
            <v-col cols="4">
              <div class="mt-2">
                <div
                  v-for="item in viewGroupData.data.description"
                  :key="item.catalogName"
                >
                  <b>{{ item.CatalogName }}</b
                  >: <span class="teal--text">{{ item.CatalogValues }}</span>
                </div>
              </div>
            </v-col>
            <v-col cols="8">
              <v-card>
                <v-list>
                  <v-list-group color="teal accent-4">
                    <template v-slot:activator>
                      <v-list-item-title class="text-center"
                        >Анализируемые аптеки</v-list-item-title
                      >
                    </template>
                    <v-list-item dense>
                      <v-data-table
                        class="elevation-1 table-width my-1"
                        :headers="viewGroupData.data.countHeaders"
                        :items="viewGroupData.data.marketPharmsCount"
                        hide-details
                        dense
                        rows
                        :options="{ itemsPerPage: 5 }"
                      >
                      </v-data-table>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <draggable @start="drag = true" v-model="selectedHeaders">
                <transition-group>
                  <v-btn
                    x-small
                    tile
                    v-for="item in selectedHeaders"
                    :color="item.isActive ? colors.active : colors.passive"
                    :key="item.value"
                    dark
                    class="mx-1 my-1"
                    @click="setActiveItem(item.value)"
                    elevation="3"
                  >
                    {{ item.text.replaceAll("_", " ") }}
                  </v-btn>
                </transition-group>
              </draggable>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="selectedHeaders.filter((x) => x.isActive)"
                :items="this.myArray.filter((x) => x.show == true)"
                @click:row="handleClick"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:item.open="{ item }">
                  <v-icon v-if="item.open == false && item.leaf == false" white>
                    mdi-plus
                  </v-icon>
                  <v-icon
                    v-else-if="item.open == true && item.leaf == false"
                    white
                  >
                    mdi-minus
                  </v-icon>
                </template>
                <template v-slot:item.name="{ item }">
                  <tr>
                    <td
                      class="text-start"
                      v-bind:style="{ 'padding-left': item.level * 15 + 'px' }"
                    >
                      {{ item.name }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="tab == 1">
          <v-data-table
            class="elevation-1 table-width my-1"
            :headers="viewGroupData.data.pharmsHeaders"
            :items="viewGroupData.data.marketPharms"
            hide-details
            dense
            rows
            :options="{ itemsPerPage: 50 }"
          >
          </v-data-table>
        </div>
        <div v-else></div>
      </div>
      <div v-else>
        <v-card
          elevation="0"
          class="d-flex align-center justify-center text-center"
          height="70vh"
        >
          <div>
            <v-row>
              <v-col cols="12">
                <v-icon color="red" size="80">mdi-alert-circle</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="text-h4 font-weight-bolder red--text">
                  {{
                    currentLanguage == "ru"
                      ? string_values.find((x) => x.value == "error_message")
                          .nameRu
                      : string_values.find((x) => x.value == "error_message")
                          .nameEn
                  }}
                </span>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { string_values } from "../../assets/strings/string_values";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
    userReportId: 0,
    loader: true,
    selectedHeaders: [],
    myArray: [],
    id: 0,
    colors: {
      active: "red",
      passive: "#3C999E",
    },
    categories: [
      {
        name: "ALL",
        isActive: true,
        id: 6,
        isShow: false,
      },
      {
        name: "A1",
        isActive: true,
        id: 1,
        isShow: false,
      },
      {
        name: "A2",
        isActive: true,
        id: 2,
        isShow: false,
      },
      {
        name: "B",
        isActive: true,
        id: 3,
        isShow: false,
      },
      {
        name: "C",
        isActive: true,
        id: 4,
        isShow: false,
      },
      {
        name: "D",
        isActive: true,
        id: 5,
        isShow: false,
      },
    ],
    tab: null,
  }),
  components: {
    draggable,
  },
  computed: {
    ...mapGetters({
      getViewGroupData: "unload/GET_VIEW_GROUP_DATA",
      getUserReport: "userReport/GET_USER_REPORT",
    }),
    viewGroupData() {
      return this.getViewGroupData;
    },
    userReport() {
      return this.getUserReport;
    },
  },
  methods: {
    ...mapActions({
      generateReportViewGroup: "unload/GENERATE_VIEW_GROUP",
      getUserReportById: "userReport/GET_USER_REPORT_BY_ID",
    }),
    setActiveItem(value) {
      var currentItem = this.selectedHeaders.find((x) => x.value == value);
      if (currentItem != undefined) {
        if (currentItem.isActive == false) currentItem.isActive = true;
        else currentItem.isActive = false;
      }
    },
    viewCategory(category) {
      var categories = this.categories.map(function (x) {
        return {
          name: x.name,
          isActive: x.name == category.name ? true : false,
        };
      });

      this.categories = categories;

      var selectedCategories = this.selectedHeaders
        .filter((x) => x.text.includes(category.name) && x.isShow)
        .map(function (x) {
          return { text: x.text };
        })
        .map((x) => x.text);
      this.selectedHeaders.forEach(function (element) {
        if (selectedCategories.includes(element.text) || element.type == 1)
          element.isActive = true;
        else element.isActive = false;
      });
    },
    iterateChildItems(childItem, level, parent, parents) {
      var keys = Object.keys(childItem);
      const obj = {
        name: childItem[keys[level - 1]],
      };

      let keyLength = childItem["ChildItems"] ? keys.length - 1 : keys.length;

      for (let k = level; k < keyLength; k++) {
        obj[keys[k]] = childItem[keys[k]];
      }
      obj["level"] = level;
      obj["parent"] = parent;
      obj["id"] = ++this.id;
      obj["show"] = false;
      obj["open"] = null;
      obj["leaf"] = true;
      if (level != 2) {
        obj["parents"] = parents.slice();
        obj["parents"].push(parent);
      } else {
        obj["parents"] = [parents];
      }

      this.myArray.push(obj);
      if (childItem["ChildItems"]) {
        obj["open"] = false;
        obj["leaf"] = false;
        level++;
        for (let j = 0; j < childItem["ChildItems"].length; j++) {
          this.iterateChildItems(
            childItem["ChildItems"][j],
            level,
            obj["id"],
            obj["parents"]
          );
        }
      }
    },
    handleClick(value) {
      for (let i = 0; i < this.myArray.length; i++) {
        if (this.myArray[i].parent == value.id) {
          this.myArray[i].show = !this.myArray[i].show;
        }
        if (this.myArray[i].id == value.id) {
          this.myArray[i].open = !this.myArray[i].open;
        }
        if (value.open == false && this.myArray[i].parents.includes(value.id)) {
          this.myArray[i].show = false;
          this.myArray[i].open = false;
        }
      }
    },
  },
  async created() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }

    this.userReportId = this.$route.params.id;

    if (this.userReport.length == 0) {
      await this.getUserReportById({
        userReportId: this.userReportId,
      });
    }

    var selectedPharmCategories = this.userReport.data.userReportCatalogs
      .filter((x) => x.catalogId == 31)
      .map((x) => x.value);

    if (selectedPharmCategories.length == 0)
      selectedPharmCategories = [1, 2, 3, 4, 5, 6];

    await this.generateReportViewGroup({
      UserReportId: parseInt(this.userReportId, 10),
      PharmCategories: selectedPharmCategories,
    });

    if (this.viewGroupData.succeeded) {
      this.categories.forEach((x) => {
        x.isShow = selectedPharmCategories.includes(x.id);
      });

      this.selectedHeaders = this.viewGroupData.data.headers.map(function (x) {
        return {
          class: x.class,
          text: x.text,
          value: x.value,
          isActive: true,
          type: x.type,
        };
      });
      this.selectedHeaders[0].value = "name";
      this.selectedHeaders[0].text = "Наименование";
      this.selectedHeaders.unshift({
        text: "",
        value: "open",
        class: "teal accent-4 white--text",
        type: 1,
        isActive: true,
      });

      for (let i = 0; i < this.viewGroupData.data.items[0].length; i++) {
        var keys = Object.keys(this.viewGroupData.data.items[0][i]);
        this.level = 1;
        const obj = {
          name: this.viewGroupData.data.items[0][i][keys[this.level - 1]],
        };
        let keyLength = this.viewGroupData.data.items[0][i]["ChildItems"]
          ? keys.length - 1
          : keys.length;
        for (let k = this.level; k < keyLength; k++) {
          obj[keys[k]] = this.viewGroupData.data.items[0][i][keys[k]];
        }
        obj["level"] = this.level;
        obj["parent"] = null;
        obj["id"] = ++this.id;
        obj["show"] = true;
        obj["open"] = null;
        obj["leaf"] = true;
        obj["parents"] = [];
        this.myArray.push(obj);

        if (this.viewGroupData.data.items[0][i]["ChildItems"]) {
          obj["open"] = false;
          obj["leaf"] = false;
          let p = obj["id"];
          for (
            let j = 0;
            j < this.viewGroupData.data.items[0][i]["ChildItems"].length;
            j++
          ) {
            this.iterateChildItems(
              this.viewGroupData.data.items[0][i]["ChildItems"][j],
              2,
              obj["id"],
              p
            );
          }
        }
      }
    }

    this.loader = false;
  },
};
</script>

<style scoped>
.table-width {
  width: 100%;
}

.table tbody tr:not(:last-child) {
  border-bottom: solid 1px #dddddd;
}
</style>
