export default function (instance) {
    return {
        getAll(payload) {
            return instance.get(payload);
        },
        getAllCompanies() {
            return instance.get('/Company')
        },
        getAllUserFilter(payload) {
            return instance.get(payload);
        }
    }
}
