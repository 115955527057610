<template>
  <v-container>
    <v-row justify="center" align="center" class="my-2">
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center py-0">
        <v-autocomplete
          outlined
          item-text="nameRu"
          item-value="id"
          :items="companies.data"
          dense
          label="Выберите компанию"
          color="teal"
          v-model="selectedCompany"
          prepend-icon="mdi-domain"
          :loading="companiesLoading"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center py-0">
        <v-autocomplete
          outlined
          item-text="name"
          item-value="id"
          :items="companyUsers.data"
          dense
          label="Выберите пользователя"
          color="teal"
          v-model="selectedUser"
          prepend-icon="mdi-account-group"
          :loading="companyUsersLoading"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div v-if="!loading">
      <v-row justify="center" align="center">
        <v-col cols="12" class="text-center">
          <span class="text-h6 font-weight-bold"
            >Информация о пользователе</span
          >
          <v-divider class="my-2" />
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="10">
          <v-card elevation="5" class="px-4 py-4" :loading="profileLoading">
            <template slot="progress">
              <v-progress-linear color="teal" indeterminate></v-progress-linear>
            </template>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
                  <v-avatar class="profile" color="grey" size="164">
                    <v-img
                      src="https://www.w3schools.com/howto/img_avatar.png"
                    ></v-img>
                  </v-avatar>
                  <div>
                    <span class="text-h6 font-weight-normal">{{
                      userInfo.userName
                    }}</span>
                  </div>
                  <div>
                    <span class="font-weight-bold">{{
                      userInfo.companyName
                    }}</span>
                  </div>
                </v-col>
                <v-col cols="12" md="8" lg="8" xl="8" class="text-center">
                  <v-list>
                    <v-list-item>
                      <v-text-field
                        color="teal lighten-1"
                        dense
                        outlined
                        v-model="userInfo.userName"
                        counter="40"
                        hint="Введите имя пользователя"
                        label="Имя пользователя"
                        prepend-icon="mdi-account"
                        disabled
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field
                        color="teal lighten-1"
                        dense
                        outlined
                        v-model="userInfo.email"
                        hint="Введите e-mail пользователя"
                        label="E-Mail пользователя"
                        prepend-icon="mdi-at"
                        :rules="emailRules"
                        disabled
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-autocomplete
                        outlined
                        item-text="nameRu"
                        item-value="id"
                        :items="companies.data"
                        dense
                        label="Компания пользователя"
                        hint="Введите компанию пользователя"
                        color="teal"
                        v-model="selectedUserCompany"
                        prepend-icon="mdi-domain"
                      >
                      </v-autocomplete>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field
                        color="teal lighten-1"
                        dense
                        outlined
                        v-model="userInfo.password"
                        hint="Введите пароль пользователя"
                        label="Пароль пользователя"
                        prepend-icon="mdi-lock"
                      ></v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            color="teal lighten-1"
                            dense
                            outlined
                            v-model="userInfo.from"
                            hint="Период от: {ГГГГ.ММ.ДД}"
                            label="Период от"
                            prepend-icon="mdi-calendar"
                            v-mask="'####-##-##'"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            color="teal lighten-1"
                            dense
                            outlined
                            v-model="userInfo.to"
                            hint="Период до: {ГГГГ.ММ.ДД}"
                            label="Период до"
                            prepend-icon="mdi-calendar"
                            v-mask="'####-##-##'"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item>
                      <v-checkbox
                        label="Заблокирован?"
                        v-model="userInfo.isBlocked"
                        color="teal"
                      >
                      </v-checkbox>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-divider class="my-2" />
              <v-row>
                <v-col cols="12" md="6" lg="6" xl="6">
                  <div class="text-center my-2">
                    <span class="font-weight-bold"
                      >Последние выгруженные отчеты</span
                    >
                  </div>
                  <v-data-table
                    dense
                    :headers="lastUnloadReports.headers"
                    :items="lastUnloadReports.items"
                    :items-per-page="5"
                    class="elevation-2"
                    hide-default-footer
                  >
                    <template v-slot:item.createDate="{ item }">
                      <span>{{
                        new Date(item.createDate).toLocaleString()
                      }}</span>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6">
                  <div class="text-center my-2">
                    <span class="font-weight-bold"
                      >Последние входы в систему</span
                    >
                  </div>
                  <v-data-table
                    dense
                    :headers="lastEntrances.headers"
                    :items="lastEntrances.items"
                    :items-per-page="5"
                    class="elevation-2"
                    hide-default-footer
                  >
                    <template v-slot:item.enterDate="{ item }">
                      <span>{{
                        new Date(item.enterDate).toLocaleString()
                      }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn text color="success" @click="editUser()">Применить</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else class="text-center">
      <v-icon x-large color="red">mdi-cloud-off-outline</v-icon>
      <h4>Выберите пользователя</h4>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UserInformation",
  data: () => ({
    companiesLoading: true,
    companyUsersLoading: false,
    loading: true,
    profileLoading: true,
    selectedCompany: 0,
    selectedUser: 0,
    selectedUserCompany: 0,
    lastUnloadReports: {
      headers: [
        {
          text: "Наименование отчета",
          sortable: false,
          align: "center",
          value: "name",
          class: "teal lighten-1 white--text",
        },
        {
          text: "Описание",
          sortable: false,
          align: "center",
          value: "description",
          class: "teal lighten-1 white--text",
        },
        {
          text: "Дата формирования",
          sortable: false,
          align: "center",
          value: "createDate",
          class: "teal lighten-1 white--text",
        },
      ],
      items: [],
    },
    lastEntrances: {
      headers: [
        {
          text: "Дата входа",
          sortable: false,
          align: "center",
          value: "enterDate",
          class: "blue-grey darken-1 white--text",
        },
        {
          text: "IP адрес",
          sortable: false,
          align: "center",
          value: "ipAddress",
          class: "blue-grey darken-1 white--text",
        },
      ],
      items: [],
    },
    emailRules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Введите корректный E-Mail",
    ],
  }),
  computed: {
    ...mapGetters({
      getUserInformationByUserId: "unload/GET_USER_INFORMATION_BY_USER_ID",
      getCompanies: "item/GET_COMPANIES",
      getCompanyUsers: "identity/GET_COMPANY_USERS",
      getEditStatus: "identity/GET_EDIT_STATUS",
    }),
    userInfo() {
      return this.getUserInformationByUserId;
    },
    companies() {
      return this.getCompanies;
    },
    companyUsers() {
      return this.getCompanyUsers;
    },
    editStatus() {
      return this.getEditStatus;
    },
  },
  methods: {
    ...mapActions({
      generateUserInformationByUserId:
        "unload/GENERATE_USER_INFORMATION_BY_USER_ID",
      getCompaniesFromAPI: "item/GET_COMPANIES_FROM_API",
      getCompanyUsersFromAPI: "identity/GET_COMPANY_USERS_FROM_API",
      edit: "identity/EDIT",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
    }),
    async getUserEntranceByUserIdFromAPI(userId) {
      await this.generateUserInformationByUserId({
        userId: userId,
      });
    },
    async getUserEntrance(userId) {
      this.profileLoading = true;

      await this.getUserEntranceByUserIdFromAPI(userId);

      this.selectedUserCompany = this.userInfo.companyId;

      this.lastEntrances.items = [];
      this.lastUnloadReports.items = [];
      if (this.userInfo.lastUserEntrances != null)
        this.lastEntrances.items = JSON.parse(this.userInfo.lastUserEntrances);

      if (this.userInfo.lastUnloadReports != null) {
        this.lastUnloadReports.items = JSON.parse(
          this.userInfo.lastUnloadReports
        );
      }

      this.profileLoading = false;
    },
    async editUser() {
      await this.edit({
        userId: this.selectedUser,
        companyId: this.selectedUserCompany,
        password: this.userInfo.password,
        isBlocked: this.userInfo.isBlocked,
        from: this.userInfo.from,
        to: this.userInfo.to,
      });
      if (this.editStatus.data) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Изменения успешно внесены!",
        });
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при внесении изменений!",
        });
      }
    },
  },
  watch: {
    selectedCompany: function () {
      this.companyUsersLoading = true;
      this.getCompanyUsersFromAPI({
        companyId: this.selectedCompany,
      });
      this.companyUsersLoading = false;
    },
    selectedUser: function () {
      this.loading = true;
      this.getUserEntrance(this.selectedUser);
      this.loading = false;
    },
  },
  async created() {
    this.companiesLoading = true;
    await this.getCompaniesFromAPI();
    this.companiesLoading = false;
  },
};
</script>
