<template>
  <div>
    <v-card elevation="4" :loading="loading">
      <template slot="progress">
        <v-progress-linear color="red" indeterminate></v-progress-linear>
      </template>
      <v-subheader style="background-color: #3fa0a5; color: white">
        {{
          currentLanguage == "ru"
            ? string_values.find((x) => x.value == "filter").nameRu
            : string_values.find((x) => x.value == "filter").nameEn
        }}
      </v-subheader>
      <v-list dense style="overflow-y: scroll; height: 70vh">
        <template v-for="(item, index) in catalogs">
          <v-list-group
            no-action
            :key="item.id + '_cat'"
            color="red"
            :append-icon="
              item.childCatalogs.length > 0
                ? 'mdi-chevron-down'
                : 'mdi-circle-small'
            "
            @click="getSelectedCatalog(item)"
          >
            <v-list-item slot="activator">
              <v-list-item-title>
                {{ currentLanguage == "ru" ? item.nameRu : item.nameEn }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item-group>
              <template v-for="(subItem, jndex) in item.childCatalogs">
                <v-list-item
                  :key="subItem.id + '_subcat'"
                  @click="getSelectedCatalog(subItem)"
                >
                  <v-list-item-title class="text-center">
                    {{
                      currentLanguage == "ru" ? subItem.nameRu : subItem.nameEn
                    }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="jndex < item.childCatalogs.length - 1"
                  :key="jndex"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list-group>
          <v-divider
            v-if="index < catalogs.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { string_values } from "../../../assets/strings/string_values";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CatalogFilter",
  data: () => ({
    loading: true,
    string_values: string_values,
    currentLanguage: "ru",
    catalogs: [],
  }),
  computed: {
    ...mapGetters({
      getReportTypeCatalogs: "catalog/GET_REPORT_TYPE_CATALOGS",
      getCatalogRequestConfigs: "config/GET_CATALOG_REQUEST_CONFIGS",
      getItems: "item/GET_ITEMS",
      getUserReport: "userReport/GET_USER_REPORT",
    }),
    reportTypeCatalogs() {
      return this.getReportTypeCatalogs;
    },
    catalogRequestConfigs() {
      return this.getCatalogRequestConfigs;
    },
    items() {
      return this.getItems;
    },
    userReport() {
      return this.getUserReport;
    },
  },
  methods: {
    ...mapActions({
      getItemsFromAPI: "item/GET_ITEMS_FROM_API",
      commitSelectedCatalogRequestConfig:
        "config/COMMIT_SELECTED_CATALOG_REQUEST_CONFIG",
      commitLoader: "loader/GENERATE_LOADER",
      commitCatalogQuery: "item/COMMIT_CATALOG_QUERY",
    }),
    async getSelectedCatalog(item) {
      if (item.childCatalogs?.length == 0 || item.childCatalogs == undefined) {
        this.commitLoader({
          show: true,
          color: "red",
        });
        var catalogItem = this.catalogRequestConfigs.find(
          (x) => x.catalogId == item.id && x.catalogRequestTypeId == 1
        );

        if (catalogItem != undefined) {
          var payload =
            catalogItem.userReportId != null
              ? catalogItem.url +
                catalogItem.pageCount +
                "1" +
                catalogItem.pageSize +
                "1000" +
                catalogItem.userReportId +
                this.userReport.data.id +
                catalogItem.currentCatalogID +
                catalogItem.catalogId
              : catalogItem.url +
                catalogItem.pageCount +
                "1" +
                catalogItem.pageSize +
                "1000";
          await this.getItemsFromAPI(payload);
          var obj = {
            url: catalogItem.url,
            pageCount: catalogItem.pageCount,
            pageSize: catalogItem.pageSize,
            currentPageCount: 1,
            currentPageSize: 1000,
            totalPages: this.items.totalPages,
            catalogId: catalogItem.catalogId,
            userReportId: catalogItem.userReportId,
            currentCatalogID: catalogItem.currentCatalogID,
            userReportIdd: this.userReport.data.id,
            catalogQuery: catalogItem.query,
            currentCatalogIdd: catalogItem.catalogId,
            currentCatalogQuery: "",
          };

          await this.commitSelectedCatalogRequestConfig(obj);
        }
        this.commitLoader({
          show: false,
          color: "red",
        });
      }
      this.commitCatalogQuery(null);
      document
        .getElementById("catalogList")
        .scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  watch: {
    reportTypeCatalogs: function (val) {
      this.loading = true;
      var tempCatalogs = val.data.map((x) => x.catalog);
      var resultCatalogs = [];
      tempCatalogs.forEach((element) => {
        var id = element.id;
        var nameRu = element.nameRu;
        var nameEn = element.nameEn;
        if (element.parentCatalogRelations.length > 0) {
          var childElements = element.parentCatalogRelations
            .map((x) => x.childCatalog)
            .map(function (x) {
              return {
                id: x.id,
                nameRu: x.nameRu,
                nameEn: x.nameEn,
              };
            });
          resultCatalogs.push({
            id: id,
            nameRu: nameRu,
            nameEn: nameEn,
            childCatalogs: childElements,
          });
        } else {
          resultCatalogs.push({
            id: id,
            nameRu: nameRu,
            nameEn: nameEn,
            childCatalogs: [],
          });
        }
      });
      this.catalogs = resultCatalogs;
      this.loading = false;
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }
  },
};
</script>
