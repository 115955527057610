import Vue from 'vue'
import Vuex from 'vuex'
import cube from './report/cube'
import catalog from './report/catalog'
import config from './report/config'
import filter from './report/filter'
import item from './catalog/item'
import company from './catalog/company'
import userReport from './report/userReport'
import snackbar from './common/snackbar'
import loader from './common/loader'
import unload from './unload/unload'
import identity from './identity/identity'
import tab from './common/tab'
import userCatalog from './userCatalog/userCatalog'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {cube, catalog, config, item, filter, userReport, snackbar, loader, unload, identity, tab, company, userCatalog}
})
