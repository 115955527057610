import userCatalog from '../../api/index'

const addItems = (query) => {
    return new Promise((resolve) => {
        resolve(userCatalog.userCatalog.addItems(query))
    })
}

const deleteItems = (query) => {
    return new Promise((resolve) => {
        resolve(userCatalog.userCatalog.deleteItems(query))
    })
}

const getItems = (query) => {
    return new Promise((resolve) => {
        resolve(userCatalog.userCatalog.getItems(query))
    })
}

const copyItems = (query) => {
    return new Promise((resolve) => {
        resolve(userCatalog.userCatalog.copyItems(query))
    })
}

export default {
    namespaced: true,
    state: {
        addCatalogItemCount: null,
        deleteCatalogItemCount: null,
        copyCatalogItemCount: null,
        items: [],
        selectedUser: null
    },
    getters: {
        GET_ADD_CATALOG_ITEMS_COUNT(state) {
            return state.addCatalogItemCount;
        },
        GET_DELETED_CATALOG_ITEMS_COUNT(state) {
            return state.deleteCatalogItemCount;
        },
        GET_COPIED_CATALOG_ITEMS_COUNT(state) {
            return state.copyCatalogItemCount;
        },
        GET_ITEMS(state) {
            return state.items;
        },
        GET_SELECTED_USER(state) {
            return state.selectedUser;
        },
    },
    mutations: {
        SET_ADD_CATALOG_ITEMS_COUNT(state, payload) {
            state.addCatalogItemCount = payload;
        },
        SET_DELETED_CATALOG_ITEMS_COUNT(state, payload) {
            state.deleteCatalogItemCount = payload;
        },
        SET_COPIED_CATALOG_ITEMS_COUNT(state, payload) {
            state.copyCatalogItemCount = payload;
        },
        SET_ITEMS(state, payload) {
            state.items = payload;
        },
        SET_SELECTED_USER(state, payload) {
            state.selectedUser = payload;
        },
    },
    actions: {
        async GET_ITEMS_BY_USER_ID({ commit }, payload) {
            await getItems(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ITEMS', data);
                    }
                }
            )
        },
        async ADD_CATALOG_ITEMS({ commit }, payload) {
            await addItems(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ADD_CATALOG_ITEMS_COUNT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ADD_CATALOG_ITEMS_COUNT', { data: 0 });
                })
        },
        async DELETE_CATALOG_ITEMS({ commit }, payload) {
            await deleteItems(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_DELETED_CATALOG_ITEMS_COUNT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_DELETED_CATALOG_ITEMS_COUNT', { data: 0 });
                })
        },
        async COPY_CATALOG_ITEMS({ commit }, payload) {
            await copyItems(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_COPIED_CATALOG_ITEMS_COUNT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COPIED_CATALOG_ITEMS_COUNT', { data: 0 });
                })
        },
        COMMIT_SELECTED_USER({ commit }, payload) {
            commit('SET_SELECTED_USER', payload);
        },
    }
}