<template>
  <v-container>
    <v-row justify="center" align="center" class="my-2">
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center py-0">
        <v-autocomplete
          outlined
          item-text="nameRu"
          item-value="id"
          :items="companies.data"
          dense
          label="Выберите компанию"
          color="deep-orange lighten-2"
          v-model="selectedCompany"
          prepend-icon="mdi-domain"
          :loading="companiesLoading"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center py-0">
        <v-autocomplete
          outlined
          item-text="name"
          item-value="id"
          :items="companyUsers.data"
          dense
          label="Выберите пользователя"
          color="deep-orange lighten-2"
          v-model="selectedUser"
          prepend-icon="mdi-account-group"
          :loading="companyUsersLoading"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center py-0">
        <v-autocomplete
          outlined
          item-text="name"
          item-value="id"
          :items="companyUsers.data"
          dense
          label="Скопировать с"
          color="green"
          v-model="copiedUser"
          prepend-icon="mdi-content-copy"
          :loading="companyUsersLoading"
        >
          <template v-slot:append-outer>
            <v-btn
              small
              text
              fab
              color="green"
              @click="copyUserConstraints()"
              :loading="copyLoading"
              :disabled="copyLoading"
            >
              <v-icon>mdi-check-circle-outline</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <span class="text-h6 font-weight-bold">Ограничения пользователя</span>
        <v-divider class="my-2" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="3" lg="3" xl="3" class="text-center">
        <Filterr />
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
        <Listt />
      </v-col>
      <v-col cols="12" md="1" lg="1" xl="1" class="text-center">
        <Actionn />
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
        <Markett />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Filterr from "./UserFilter/Filterr.vue";
import Listt from "./UserFilter/List.vue";
import Markett from "./UserFilter/Market.vue";
import Actionn from "./UserFilter/Action.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "UserFilter",
  components: {
    Filterr,
    Listt,
    Markett,
    Actionn,
  },
  computed: {
    ...mapGetters({
      getCompanies: "item/GET_COMPANIES",
      getCompanyUsers: "identity/GET_COMPANY_USERS",
      getCopiedCatalogItemsCount: "userCatalog/GET_COPIED_CATALOG_ITEMS_COUNT",
    }),
    companies() {
      return this.getCompanies;
    },
    companyUsers() {
      return this.getCompanyUsers;
    },
    copiedCatalogItemsCount() {
      return this.getCopiedCatalogItemsCount;
    },
  },
  data: () => ({
    companiesLoading: false,
    companyUsersLoading: false,
    selectedCompany: 0,
    selectedUser: 0,
    copiedUser: 0,
    copyLoading: false,
  }),
  methods: {
    ...mapActions({
      getCatalogsByReportTypeId: "catalog/GET_BY_REPORT_TYPE_ID",
      getCatalogRequestConfigs: "config/GET_CATALOG_REQUEST_CONFIGS_FROM_API",
      getCatalogItems: "userCatalog/GET_ITEMS_BY_USER_ID",
      getCompaniesFromAPI: "item/GET_COMPANIES_FROM_API",
      getCompanyUsersFromAPI: "identity/GET_COMPANY_USERS_FROM_API",
      commitSelectedUser: "userCatalog/COMMIT_SELECTED_USER",
      copyCatalogItemsFromApi: "userCatalog/COPY_CATALOG_ITEMS",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
    }),
    async copyUserConstraints() {
      this.copyLoading = true;
      await this.copyCatalogItemsFromApi({
        sourceUserId: this.copiedUser,
        targetUserId: this.selectedUser,
      });

      if (this.copiedCatalogItemsCount != null) {
        await this.getCatalogItems({
          userId: this.selectedUser,
        });

        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Копирование прошло успешно!",
        });
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "yellow darken-2",
          text: "Ошибка при копировании!",
        });
      }
      this.copyLoading = false;
    },
  },
  watch: {
    selectedCompany: function () {
      this.companyUsersLoading = true;
      this.getCompanyUsersFromAPI({
        companyId: this.selectedCompany,
      });
      this.companyUsersLoading = false;
    },
    selectedUser: function () {
      this.getCatalogItems({
        userId: this.selectedUser,
      });
      this.commitSelectedUser(this.selectedUser);
    },
  },
  async created() {
    await this.getCatalogsByReportTypeId({
      reportTypeId: 5,
    });
    await this.getCatalogRequestConfigs();

    this.companiesLoading = true;
    await this.getCompaniesFromAPI();
    this.companiesLoading = false;
  },
};
</script>
