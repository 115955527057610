<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="loader"> </v-skeleton-loader>
    <div v-if="!loader">
      <div v-if="viewData.succeeded">
        <v-tabs grow color="teal" v-model="tab">
          <v-tab>Отчет</v-tab>
          <v-tab v-if="viewData.data.marketPharms.length > 0">Аптеки</v-tab>
        </v-tabs>
        <div v-if="tab == null || tab == 0">
          <v-row>
            <v-col cols="4">
              <div class="mt-2">
                <div
                  v-for="item in viewData.data.description"
                  :key="item.catalogName"
                >
                  <b>{{ item.CatalogName }}</b
                  >: <span class="teal--text">{{ item.CatalogValues }}</span>
                </div>
              </div>
            </v-col>
            <v-col cols="8">
              <v-card>
                <v-list>
                  <v-list-group color="teal accent-4">
                    <template v-slot:activator>
                      <v-list-item-title class="text-center">
                        {{
                          currentLanguage == "ru"
                            ? string_values.find(
                                (x) => x.value == "analyzed_pharmacies"
                              ).nameRu
                            : string_values.find(
                                (x) => x.value == "analyzed_pharmacies"
                              ).nameEn
                        }}
                      </v-list-item-title>
                    </template>
                    <v-list-item dense>
                      <v-data-table
                        class="elevation-1 table-width my-1"
                        :headers="viewData.data.countHeaders"
                        :items="viewData.data.marketPharmsCount"
                        hide-details
                        dense
                        rows
                        :options="{ itemsPerPage: 5 }"
                      >
                      </v-data-table>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                x-small
                tile
                v-for="item in categories.filter((x) => x.isShow)"
                :color="item.isActive ? colors.active : colors.passive"
                :key="item.name"
                dark
                class="mx-1 my-1"
                elevation="3"
                @click="viewCategory(item)"
              >
                {{ item.name }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <draggable @start="drag = true" v-model="selectedHeaders">
                <transition-group>
                  <v-btn
                    x-small
                    tile
                    v-for="item in selectedHeaders"
                    :color="item.isActive ? colors.active : colors.passive"
                    :key="item.value"
                    dark
                    class="mx-1 my-1"
                    @click="setActiveItem(item.value)"
                    elevation="3"
                  >
                    {{ item.text.replaceAll("_", " ") }}
                  </v-btn>
                </transition-group>
              </draggable>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div v-if="viewData.data.groupedItems != undefined">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table class="elevation-1" id="dataTable">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              v-for="(header, index) in selectedHeaders.filter(
                                (x) => x.isActive
                              )"
                              :key="'hdr_' + index"
                              :class="header.class"
                            >
                              {{ header.text }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in paginatedItems"
                            :key="'itm_row_' + index"
                          >
                            <td
                              v-for="(header, index) in selectedHeaders.filter(
                                (x) =>
                                  x.isActive &&
                                  x.type == 1 &&
                                  !x.text.includes('Рынок')
                              )"
                              :key="'itm_key_' + index"
                            >
                              {{ item.key[header.value.toLowerCase()] }}
                            </td>
                            <td
                              v-for="(header, index) in selectedHeaders.filter(
                                (x) =>
                                  (x.isActive && x.type == 3) ||
                                  x.text.includes('Рынок')
                              )"
                              :key="'itm_items_' + index"
                              class="px-0"
                            >
                              <template
                                v-for="(innerItem, index) in item.items"
                              >
                                <div
                                  :key="'itm_in_' + index"
                                  class="text-center"
                                >
                                  {{ innerItem[header.value] }}
                                </div>
                                <v-divider
                                  v-if="index + 1 < item.items.length"
                                  :key="'itm_in_dvd_' + index"
                                />
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card max-width="60%" elevation="0" class="mx-auto">
                      <v-pagination
                        v-model="currentPage"
                        :length="totalPages"
                        @input="changePage"
                        circle
                        color="teal"
                      ></v-pagination>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-data-table
                  class="elevation-1"
                  :headers="selectedHeaders.filter((x) => x.isActive)"
                  :items="viewData.data.items"
                  hide-details
                  dense
                  rows
                  :options="{ itemsPerPage: 50 }"
                  :sort-by="
                    viewData.data.headers
                      .filter((x) => x.type == 1)
                      .map((x) => x.text)
                  "
                  multi-sort
                >
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="tab == 1">
          <v-data-table
            class="elevation-1 table-width my-1"
            :headers="viewData.data.pharmsHeaders"
            :items="viewData.data.marketPharms"
            hide-details
            dense
            rows
            :options="{ itemsPerPage: 50 }"
          >
          </v-data-table>
        </div>
        <div v-else></div>
      </div>
      <div v-else>
        <v-card
          elevation="0"
          class="d-flex align-center justify-center text-center"
          height="70vh"
        >
          <div>
            <v-row>
              <v-col cols="12">
                <v-icon color="red" size="80">mdi-alert-circle</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="text-h4 font-weight-bolder red--text">
                  {{
                    currentLanguage == "ru"
                      ? string_values.find((x) => x.value == "error_message")
                          .nameRu
                      : string_values.find((x) => x.value == "error_message")
                          .nameEn
                  }}
                </span>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { string_values } from "../../assets/strings/string_values";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
    userReportId: 0,
    loader: true,
    selectedHeaders: [],
    colors: {
      active: "red",
      passive: "#3C999E",
    },
    categories: [
      {
        name: "ALL",
        isActive: true,
        id: 6,
        isShow: false,
      },
      {
        name: "A1",
        isActive: true,
        id: 1,
        isShow: false,
      },
      {
        name: "A2",
        isActive: true,
        id: 2,
        isShow: false,
      },
      {
        name: "B",
        isActive: true,
        id: 3,
        isShow: false,
      },
      {
        name: "C",
        isActive: true,
        id: 4,
        isShow: false,
      },
      {
        name: "D",
        isActive: true,
        id: 5,
        isShow: false,
      },
    ],
    tab: null,
    itemsPerPage: 50,
    currentPage: 1,
  }),
  components: {
    draggable,
  },
  computed: {
    ...mapGetters({
      getViewData: "unload/GET_VIEW_DATA",
      getUserReport: "userReport/GET_USER_REPORT",
    }),
    viewData() {
      return this.getViewData;
    },
    userReport() {
      return this.getUserReport;
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.viewData.data.groupedItems.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(
        this.viewData.data.groupedItems.length / this.itemsPerPage
      );
    },
  },
  methods: {
    ...mapActions({
      generateReportView: "unload/GENERATE_VIEW",
      getUserReportById: "userReport/GET_USER_REPORT_BY_ID",
    }),
    setActiveItem(value) {
      var currentItem = this.selectedHeaders.find((x) => x.value == value);
      if (currentItem != undefined) {
        if (currentItem.isActive == false) currentItem.isActive = true;
        else currentItem.isActive = false;
      }
    },
    viewCategory(category) {
      category.isActive = !category.isActive;
      this.categories.forEach(function (element) {
        if (element.name == category.name) {
          element.isActive = category.isActive;
        }
      });

      var selectedCategories = this.categories
        .filter((x) => x.isActive && x.isShow)
        .map(function (x) {
          return { text: x.name };
        });

      var selectedHeaders = this.selectedHeaders;

      let allHeaders = [];
      selectedCategories.forEach(function (element) {
        let elems = selectedHeaders
          .filter((x) => x.text.includes(element.text))
          .map((x) => x.text);

        allHeaders = allHeaders.concat(elems);
      });

      this.selectedHeaders.forEach(function (element) {
        if (allHeaders.includes(element.text) || element.type == 1)
          element.isActive = true;
        else element.isActive = false;
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
  },
  async created() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }

    this.userReportId = this.$route.params.id;

    if (this.userReport.length == 0) {
      await this.getUserReportById({
        userReportId: this.userReportId,
      });
    }

    var selectedPharmCategories = this.userReport.data.userReportCatalogs
      .filter((x) => x.catalogId == 31)
      .map((x) => x.value);

    if (selectedPharmCategories.length == 0)
      selectedPharmCategories = [1, 2, 3, 4, 5, 6];

    await this.generateReportView({
      UserReportId: parseInt(this.userReportId, 10),
      PharmCategories: selectedPharmCategories,
    });

    if (this.viewData.succeeded) {
      this.categories.forEach((x) => {
        x.isShow = selectedPharmCategories.includes(x.id);
      });

      this.selectedHeaders = this.viewData.data.headers.map(function (x) {
        return {
          class: x.class,
          text: x.type == 3 ? x.text + "(%)" : x.text,
          value: x.value,
          isActive: true,
          type: x.type,
        };
      });
    }
    this.loader = false;
  },
};
</script>

<style scoped>
.table-width {
  width: 100%;
}
.table tbody tr:not(:last-child) {
  border-bottom: solid 1px #dddddd;
}
#dataTable tbody tr td {
  border-right: solid 1px #dddddd;
}
</style>
