<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" lg="3" xl="3">
        <v-card
          elevation="4"
          :loading="catLoading"
          style="overflow-y: scroll; height: 80vh"
        >
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
          <v-subheader class="viortis-background">
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "categories").nameRu
                : string_values.find((x) => x.value == "categories").nameEn
            }}
            <v-spacer />
            <v-btn
              color="red"
              tile
              class="white--text"
              x-small
              elevation="0"
              @click="openAddDialog()"
            >
              {{
                currentLanguage == "ru"
                  ? string_values.find((x) => x.value == "add").nameRu
                  : string_values.find((x) => x.value == "add").nameEn
              }}
            </v-btn>
          </v-subheader>
          <v-list dense>
            <template v-for="(item, index) in userReportCategoryTypes.data">
              <v-list-group
                no-action
                :key="item.id + '_cat'"
                color="red"
                :append-icon="
                  item.userReportCategories.length > 0
                    ? 'mdi-chevron-down'
                    : 'mdi-circle-small'
                "
              >
                <v-list-item slot="activator">
                  <v-list-item-title>
                    {{ currentLanguage == "ru" ? item.nameRu : item.nameEn }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item-group>
                  <template
                    v-for="(subItem, jndex) in item.userReportCategories"
                  >
                    <v-list-item
                      :key="subItem.id + '_subcat'"
                      @click="getCategoryReports(subItem.id)"
                    >
                      <v-list-item-title class="text-center">
                        {{
                          currentLanguage == "ru"
                            ? subItem.nameRu
                            : subItem.nameEn
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider
                      v-if="jndex < item.userReportCategories.length - 1"
                      :key="jndex"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list-group>
              <v-divider
                v-if="index < userReportCategoryTypes.data.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-card class="mx-auto" :loading="loading" elevation="4" height="80vh">
          <template slot="progress">
            <v-progress-linear color="red" indeterminate></v-progress-linear>
          </template>
          <v-subheader class="viortis-background">
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "reports").nameRu
                : string_values.find((x) => x.value == "reports").nameEn
            }}
          </v-subheader>
          <div v-if="selectedUserCategoryId != 0">
            <div>
              <v-list dense style="overflow-y: scroll; height: 68vh">
                <v-list-item-group v-model="selectedItem" color="red">
                  <template v-for="(item, index) in userReports.data">
                    <v-list-item
                      :key="item.id"
                      @click="openUserReport(item.id)"
                      two-line
                    >
                      <v-list-item-icon class="mx-auto my-auto">
                        <v-icon color="#4BA1A6">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="ml-4">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ new Date(item.createDate).toLocaleString() }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-btn
                          color="#4BA1A6"
                          outlined
                          class="mx-auto my-auto"
                          @click="open()"
                        >
                          <v-icon>mdi-application-edit-outline</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-icon>
                        <v-btn
                          color="#4BA1A6"
                          outlined
                          class="mx-auto my-auto"
                          @click="deleteReportMethod()"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-divider
                      v-if="index < userReports.data.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
              <v-divider />
            </div>
            <div>
              <v-pagination
                color="teal"
                v-model="currentPage"
                :length="totalPages"
                class="px-0 py-0"
              ></v-pagination>
            </div>
          </div>
          <div v-else>
            <v-card
              class="mx-auto text-center"
              justify="center"
              align="center"
              tile
              elevation="0"
            >
              <div>
                <v-icon x-large color="red">mdi-cloud-off-outline</v-icon>
                <h4>
                  {{
                    currentLanguage == "ru"
                      ? string_values.find((x) => x.value == "select_category")
                          .nameRu
                      : string_values.find((x) => x.value == "select_category")
                          .nameEn
                  }}
                </h4>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="5" xl="5" v-if="userReport != null">
        <v-card
          class="mx-auto"
          elevation="4"
          style="overflow-y: scroll; height: 80vh"
        >
          <v-subheader class="viortis-background">
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "report_info").nameRu
                : string_values.find((x) => x.value == "report_info").nameEn
            }}
          </v-subheader>
          <v-row class="mt-2">
            <v-col cols="12">
              <div class="my-1">
                <v-subheader class="viortis-background" style="height: 24px">
                  {{
                    currentLanguage == "ru"
                      ? string_values.find((x) => x.value == "report_name")
                          .nameRu
                      : string_values.find((x) => x.value == "report_name")
                          .nameEn
                  }}
                </v-subheader>
                <span class="px-3 text-subtitle-2">
                  {{ userReport != null ? userReport.name : "" }}
                </span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn fab text x-small v-bind="attrs" v-on="on">
                      <v-icon small @click="editReportName(userReport)"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>
                    {{
                      currentLanguage == "ru"
                        ? string_values.find((x) => x.value == "change").nameRu
                        : string_values.find((x) => x.value == "change").nameEn
                    }}
                  </span>
                </v-tooltip>
              </div>
              <div class="my-1">
                <v-subheader class="viortis-background" style="height: 24px">
                  {{
                    currentLanguage == "ru"
                      ? string_values.find((x) => x.value == "report_comment")
                          .nameRu
                      : string_values.find((x) => x.value == "report_comment")
                          .nameEn
                  }}
                </v-subheader>
                <span class="px-3 text-subtitle-2">
                  {{ userReport != null ? userReport.description : "" }}
                </span>
              </div>
              <div class="my-1">
                <v-subheader class="viortis-background" style="height: 24px">
                  {{
                    currentLanguage == "ru"
                      ? string_values.find((x) => x.value == "create_date")
                          .nameRu
                      : string_values.find((x) => x.value == "create_date")
                          .nameEn
                  }}
                </v-subheader>
                <span class="px-3 text-subtitle-2">
                  {{
                    userReport != null
                      ? new Date(userReport.createDate).toLocaleString()
                      : ""
                  }}
                </span>
              </div>
              <v-subheader class="viortis-background" style="height: 24px">
                {{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "display_columns")
                        .nameRu
                    : string_values.find((x) => x.value == "display_columns")
                        .nameEn
                }}
              </v-subheader>
              <div class="my-1">
                <v-btn
                  v-for="displayCube in this.userReport.userReportCubes
                    .map((x) => x.cube)
                    .filter((x) => x.cubeTypeId == 1)"
                  :key="displayCube.id"
                  small
                  outlined
                  tile
                  color="red"
                  class="mx-1"
                >
                  {{
                    currentLanguage == "ru"
                      ? displayCube.nameRu.replaceAll("_", " ")
                      : displayCube.nameEn.replaceAll("_", " ")
                  }}
                </v-btn>
              </div>

              <v-subheader class="viortis-background" style="height: 24px">
                {{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "compute_columns")
                        .nameRu
                    : string_values.find((x) => x.value == "compute_columns")
                        .nameEn
                }}
              </v-subheader>
              <div class="my-1">
                <v-btn
                  v-for="displayCube in this.userReport.userReportCubes
                    .map((x) => x.cube)
                    .filter((x) => x.cubeTypeId == 3)"
                  :key="displayCube.id"
                  small
                  tile
                  outlined
                  color="red"
                  class="mx-1"
                >
                  {{
                    currentLanguage == "ru"
                      ? displayCube.nameRu.replaceAll("_", " ")
                      : displayCube.nameEn.replaceAll("_", " ")
                  }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="6" class="pr-1 pl-5">
              <v-btn
                outlined
                x-large
                dark
                color="#4BA1A6"
                tile
                class="text-overline"
                width="100%"
                @click="view()"
              >
                <v-icon left>mdi-file-find</v-icon>
                {{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "view").nameRu
                    : string_values.find((x) => x.value == "view").nameEn
                }}
              </v-btn>
              <v-btn
                outlined
                dark
                color="#4BA1A6"
                x-large
                tile
                class="text-overline mt-1"
                width="100%"
              >
                <v-icon left>mdi-file-find</v-icon>
                {{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "view_group").nameRu
                    : string_values.find((x) => x.value == "view_group").nameEn
                }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="pl-1 pr-5">
              <v-btn
                outlined
                dark
                color="#4BA1A6"
                x-large
                tile
                class="text-overline"
                width="100%"
                @click="excel()"
              >
                <v-icon left>mdi-file-excel</v-icon>
                {{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "excel").nameRu
                    : string_values.find((x) => x.value == "excel").nameEn
                }}
              </v-btn>
              <v-btn
                outlined
                dark
                color="#4BA1A6"
                x-large
                tile
                class="text-overline mt-1"
                width="100%"
                @click="excelGroup()"
              >
                <v-icon left>mdi-microsoft-excel</v-icon>
                {{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "excel_group").nameRu
                    : string_values.find((x) => x.value == "excel_group").nameEn
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="5" xl="5" v-else>
        <v-card class="mx-auto" elevation="4" height="80vh">
          <v-subheader class="viortis-background">
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "report_info").nameRu
                : string_values.find((x) => x.value == "report_info").nameEn
            }}
          </v-subheader>
          <v-row class="mt-2">
            <v-col cols="12" class="text-center">
              <v-icon x-large color="red">mdi-cloud-off-outline</v-icon>
              <h4>
                {{
                  currentLanguage == "ru"
                    ? string_values.find((x) => x.value == "select_report")
                        .nameRu
                    : string_values.find((x) => x.value == "select_report")
                        .nameEn
                }}
              </h4>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-top-transition"
      v-model="editDialog"
      max-width="400"
      persistent
    >
      <v-card>
        <v-toolbar color="#4BA1A6" dark>
          {{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "edit").nameRu
              : string_values.find((x) => x.value == "edit").nameEn
          }}
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                :label="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'enter_report_name')
                        .nameRu
                    : string_values.find((x) => x.value == 'enter_report_name')
                        .nameEn
                "
                color="teal"
                :placeholder="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'report_name').nameRu
                    : string_values.find((x) => x.value == 'report_name').nameEn
                "
                v-model="selectedReport.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="saveReportName()" color="teal">
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "save").nameRu
                : string_values.find((x) => x.value == "save").nameEn
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      v-model="addDialog"
      max-width="400"
      persistent
    >
      <v-card>
        <v-toolbar color="#4BA1A6" dark>
          {{
            currentLanguage == "ru"
              ? string_values.find((x) => x.value == "add_category").nameRu
              : string_values.find((x) => x.value == "add_category").nameEn
          }}
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-select
                dense
                outlined
                :label="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'select_report_type')
                        .nameRu
                    : string_values.find((x) => x.value == 'select_report_type')
                        .nameEn
                "
                color="teal"
                :items="userReportTypes"
                :item-text="currentLanguage == 'ru' ? 'nameRu' : 'nameEn'"
                item-value="id"
                v-model="addCategoryObj.type"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                :label="
                  currentLanguage == 'ru'
                    ? string_values.find(
                        (x) => x.value == 'enter_category_name'
                      ).nameRu
                    : string_values.find(
                        (x) => x.value == 'enter_category_name'
                      ).nameEn
                "
                color="teal"
                :placeholder="
                  currentLanguage == 'ru'
                    ? string_values.find((x) => x.value == 'category_name')
                        .nameRu
                    : string_values.find((x) => x.value == 'category_name')
                        .nameEn
                "
                v-model="addCategoryObj.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="red" @click="addDialog = false">
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "close").nameRu
                : string_values.find((x) => x.value == "close").nameEn
            }}
          </v-btn>
          <v-spacer />
          <v-btn text color="teal" @click="addСategoryMethod()">
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "add").nameRu
                : string_values.find((x) => x.value == "add").nameEn
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { string_values } from "../../assets/strings/string_values";
import unload from "../../api/index";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "list",
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
    userReportTypes: [],
    selectedItem: null,
    currentPage: 1,
    totalPages: 1,
    loading: false,
    userReport: null,
    editDialog: false,
    selectedReport: {},
    selectedUserCategoryId: 0,
    addDialog: false,
    addCategoryObj: {
      type: null,
      name: null,
    },
    catLoading: true,
  }),
  computed: {
    ...mapGetters({
      getUserReports: "userReport/GET_USER_REPORTS",
      getExcelFileId: "unload/GET_EXCEL_DATA",
      getExcelGroupFileId: "unload/GET_EXCEL_GROUP_DATA",
      getUpdateUserReportNameStatus:
        "userReport/GET_USER_REPORT_NAME_UPDATE_STATUS",
      getUserReportCategoryTypes: "userReport/GET_USER_REPORT_CATEGORY_TYPES",
      getAddCategoryStatus: "userReport/GET_ADD_CATEGORY_STATUS",
      getDeleteReportStatus: "userReport/GET_USER_REPORT_DELETE_STATUS",
    }),
    userReports() {
      return this.getUserReports;
    },
    excelFileId() {
      return this.getExcelFileId;
    },
    excelGroupFileId() {
      return this.getExcelGroupFileId;
    },
    updateUserReportNameStatus() {
      return this.getUpdateUserReportNameStatus;
    },
    userReportCategoryTypes() {
      return this.getUserReportCategoryTypes;
    },
    addCategoryStatus() {
      return this.getAddCategoryStatus;
    },
    deleteReportStatus() {
      return this.getDeleteReportStatus;
    },
  },
  methods: {
    ...mapActions({
      getUserReportsByUserId: "userReport/GET_USER_REPORTS_BY_USER_ID",
      generateReportLoader: "loader/GENERATE_REPORT_LOADER",
      generateExcel: "unload/GENERATE_EXCEL",
      generateExcelGroup: "unload/GENERATE_EXCEL_GROUP",
      updateUserReportName: "userReport/UPDATE_USER_REPORT_NAME",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
      getUserReportCategoryTypesFromAPI:
        "userReport/GET_USER_REPORT_CATEGORY_TYPES_FROM_API",
      addCategory: "userReport/ADD_CATEGORY",
      deleteReportFromApi: "userReport/DELETE_USER_REPORT",
    }),
    ...mapMutations({
      setSelectedTabId: "tab/SET_SELECTED_TAB_ID",
    }),
    async deleteReportMethod() {
      if (confirm("Confirm?")) {
        await this.deleteReportFromApi({
          userReportId: this.userReport.id,
        });
        console.log(this.deleteReportStatus);
        if (this.deleteReportStatus.data) {
          location.reload();
        } else {
          this.generateSnackbar({
            show: true,
            timeout: 5000,
            color: "red",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "error_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "error_message")
                    .nameEn,
          });
        }
      }
    },
    async getReportsByUserId(categoryId) {
      this.loading = true;
      await this.getUserReportsByUserId({
        CategoryId: categoryId,
        PageNumber: this.currentPage,
        PageSize: 1000,
      });
      this.totalPages = this.userReports.totalPages;
      this.currentPage = this.userReports.pageNumber;
      this.loading = false;
    },
    async openUserReport(id) {
      this.userReport = this.userReports.data.find((x) => x.id == id);
    },
    view() {
      let routeDate = this.$router.resolve({
        path: "/view/" + this.userReport.id,
      });
      window.open(routeDate.href, "_blank");
    },
    open() {
      let routeDate = this.$router.resolve({
        path: "/report/" + this.userReport.id,
      });
      window.open(routeDate.href, "_blank");
    },
    async excel() {
      this.generateReportLoader(true);
      await this.generateExcel({
        userReportId: this.userReport.id,
      });
      if (this.excelFileId.succeeded) {
        unload.unload.Download(this.excelFileId.data);
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 5000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
      this.generateReportLoader(false);
    },
    async excelGroup() {
      this.generateReportLoader(true);
      await this.generateExcelGroup({
        userReportId: this.userReport.id,
      });
      if (this.excelGroupFileId.succeeded) {
        unload.unload.Download(this.excelGroupFileId.data);
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 5000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
      this.generateReportLoader(false);
    },
    editReportName(report) {
      this.editDialog = true;
      this.selectedReport = report;
    },
    async saveReportName() {
      await this.updateUserReportName({
        userReportId: this.selectedReport.id,
        userReportName: this.selectedReport.name,
      });

      if (this.updateUserReportNameStatus.data) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "success",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "success_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "success_message")
                  .nameEn,
        });
        this.editDialog = false;
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
        this.editDialog = false;
      }
    },
    async getCategoryReports(id) {
      this.selectedUserCategoryId = id;
      await this.getReportsByUserId(this.selectedUserCategoryId);
    },
    openAddDialog() {
      this.addDialog = true;
    },
    async addСategoryMethod() {
      if (
        this.addCategoryObj.type == null ||
        this.addCategoryObj.name == null
      ) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "warning",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find(
                  (x) => x.value == "fill_correct_all_fields_message"
                ).nameRu
              : this.string_values.find(
                  (x) => x.value == "fill_correct_all_fields_message"
                ).nameEn,
        });
      }

      await this.addCategory({
        categoryTypeID: this.addCategoryObj.type,
        categoryName: this.addCategoryObj.name,
      });
      if (this.addCategoryStatus != null) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "success",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "success_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "success_message")
                  .nameEn,
        });
        this.catLoading = true;
        await this.getUserReportCategoryTypesFromAPI();
        this.catLoading = false;
        this.addDialog = false;
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
    },
  },
  watch: {
    currentPage: function () {
      this.getReportsByUserId(this.selectedUserCategoryId);
    },
  },
  async created() {
    this.setSelectedTabId(2);

    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }

    this.catLoading = true;
    await this.getUserReportCategoryTypesFromAPI();
    this.userReportTypes = this.userReportCategoryTypes.data;
    this.catLoading = false;
  },
};
</script>

<style scoped>
.viortis-background {
  background-color: #4ba1a6;
  color: white;
}
</style>
