import catalog from '../../api/index'

const GetByReportTypeId = (query) => {
    return new Promise((resolve) => {
        resolve(catalog.catalog.getByReportTypeId(query))
    })
}

export default {
    namespaced: true,
    state: {
        reportTypeCatalogs: [],
        selectedCatalogItems: [],
        selectedDeleteCatalogItems: [],
        selectedCatalogFilterItems: [],
        selectedDeleteCatalogFilterItems: [],
    },
    getters: {
        GET_REPORT_TYPE_CATALOGS(state) {
            return state.reportTypeCatalogs;
        },
        GET_SELECTED_CATALOG_ITEMS(state) {
            return state.selectedCatalogItems;
        },
        GET_SELECTED_DELETE_CATALOG_ITEMS(state) {
            return state.selectedDeleteCatalogItems;
        },
        GET_SELECTED_CATALOG_FILTER_ITEMS(state) {
            return state.selectedCatalogFilterItems;
        },
        GET_SELECTED_DELETE_CATALOG_FILTER_ITEMS(state) {
            return state.selectedDeleteCatalogFilterItems;
        },
    },
    mutations: {
        SET_REPORT_TYPE_CATALOGS(state, payload) {
            state.reportTypeCatalogs = payload;
        },
        SET_SELECTED_CATALOG_ITEMS(state, payload) {
            state.selectedCatalogItems = payload;
        },
        SET_SELECTED_DELETE_CATALOG_ITEMS(state, payload) {
            state.selectedDeleteCatalogItems = payload;
        },
        SET_SELECTED_CATALOG_FILTER_ITEMS(state, payload) {
            state.selectedCatalogFilterItems = payload;
        },
        SET_SELECTED_DELETE_CATALOG_FILTERITEMS(state, payload) {
            state.selectedDeleteCatalogFilterItems = payload;
        },
    },
    actions: {
        async GET_BY_REPORT_TYPE_ID({ commit }, payload) {
            await GetByReportTypeId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_REPORT_TYPE_CATALOGS', data);
                    }
                }
            )
        },
        COMMIT_SELECTED_CATALOG_ITEMS({ commit }, payload) {
            commit('SET_SELECTED_CATALOG_ITEMS', payload);
        },
        COMMIT_SELECTED_DELETE_CATALOG_ITEMS({ commit }, payload) {
            commit('SET_SELECTED_DELETE_CATALOG_ITEMS', payload);
        },
        COMMIT_SELECTED_CATALOG_FILTER_ITEMS({ commit }, payload) {
            commit('SET_SELECTED_CATALOG_FILTER_ITEMS', payload);
        },
        COMMIT_SELECTED_DELETE_CATALOG_FILTER_ITEMS({ commit }, payload) {
            commit('SET_SELECTED_DELETE_CATALOG_FILTERITEMS', payload);
        },
    }
}