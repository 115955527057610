import config from '../../api/index'

const getAllCatalogRequestConfig = () => {
    return new Promise((resolve) => {
        resolve(config.config.getAllCatalogRequestConfig())
    })
}

export default {
    namespaced: true,
    state: {
        requestConfigs: [],
        selectedRequestConfig: null,
        selectedRequestConfigFilter: null
    },
    getters: {
        GET_CATALOG_REQUEST_CONFIGS(state) {
            return state.requestConfigs;
        },
        GET_SELECTED_CATALOG_REQUEST_CONFIG(state) {
            return state.selectedRequestConfig;
        },
        GET_SELECTED_CATALOG_REQUEST_CONFIG_FILTER(state) {
            return state.selectedRequestConfigFilter;
        },
    },
    mutations: {
        SET_CATALOG_REQUEST_CONFIGS(state, payload) {
            state.requestConfigs = payload;
        },
        SET_SELECTED_CATALOG_REQUEST_CONFIG(state, payload) {
            state.selectedRequestConfig = payload;
        },
        SET_SELECTED_CATALOG_REQUEST_CONFIG_FILTER(state, payload) {
            state.selectedRequestConfigFilter = payload;
        },
    },
    actions: {
        async GET_CATALOG_REQUEST_CONFIGS_FROM_API({ commit }) {
            await getAllCatalogRequestConfig().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CATALOG_REQUEST_CONFIGS', data);
                    }
                }
            )
        },
        COMMIT_SELECTED_CATALOG_REQUEST_CONFIG({ commit }, payload) {
            commit('SET_SELECTED_CATALOG_REQUEST_CONFIG', payload);
        },
        COMMIT_SELECTED_CATALOG_REQUEST_CONFIG_FILTER({ commit }, payload) {
            commit('SET_SELECTED_CATALOG_REQUEST_CONFIG_FILTER', payload);
        },
    }
}
