<template>
  <div v-if="showLoginForm" class="background">
    <v-row class="d-flex align-center justify-center" style="height: 70vh">
      <v-col cols="12" md="4" lg="3" xl="3">
        <v-row>
          <v-col cols="12">
            <v-img
              alt="Viortis"
              class="shrink mr-2"
              contain
              src="../../assets/logo-dark.png"
              transition="scale-transition"
              height="70"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="1">
              <v-app-bar color="#1D6D7D" elevation="1"></v-app-bar>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-row class="justify-center align-center">
                    <v-col cols="10" class="justify-center">
                      <v-text-field
                        v-model="username"
                        :label="
                          currentLanguage == 'ru'
                            ? string_values.find((x) => x.value == 'username')
                                .nameRu
                            : string_values.find((x) => x.value == 'username')
                                .nameEn
                        "
                        :hint="
                          currentLanguage == 'ru'
                            ? string_values.find(
                                (x) => x.value == 'enter_username'
                              ).nameRu
                            : string_values.find(
                                (x) => x.value == 'enter_username'
                              ).nameEn
                        "
                        prepend-icon="mdi-account"
                        dense
                        filled
                        color="#1D6D7D"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="password"
                        :label="
                          currentLanguage == 'ru'
                            ? string_values.find((x) => x.value == 'password')
                                .nameRu
                            : string_values.find((x) => x.value == 'password')
                                .nameEn
                        "
                        type="password"
                        prepend-icon="mdi-lock"
                        filled
                        dense
                        color="#1D6D7D"
                      >
                      </v-text-field>
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        class="white--text"
                        color="#1D6D7D"
                        @click="enter()"
                        :loading="loading"
                        :disabled="loading"
                      >
                        {{
                          currentLanguage == "ru"
                            ? string_values.find((x) => x.value == "logon")
                                .nameRu
                            : string_values.find((x) => x.value == "logon")
                                .nameEn
                        }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { string_values } from "../../assets/strings/string_values";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Login",
  data: () => ({
    username: null,
    password: null,
    showLoginForm: true,
    string_values: string_values,
    currentLanguage: "ru",
    backgroundWidth: window.innerWidth,
    backgroundHeight: window.innerHeight,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      getLoginInfo: "identity/GET_LOGIN_INFO",
    }),
    loginInfo() {
      return this.getLoginInfo;
    },
  },
  methods: {
    ...mapActions({
      login: "identity/LOGIN",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
    }),
    async enter() {
      this.loading = true;
      if (
        this.username != null &&
        this.password != null &&
        this.username.length > 3 &&
        this.password.length >= 6
      ) {
        await this.login({
          Username: this.username,
          Password: this.password,
        });
        if (this.loginInfo != null) {
          if (this.loginInfo.data.isModelError) {
            this.generateSnackbar({
              show: true,
              timeout: 3000,
              color: "red",
              text:
                this.currentLanguage == "ru"
                  ? this.string_values.find((x) => x.value == "error_message")
                      .nameRu
                  : this.string_values.find((x) => x.value == "error_message")
                      .nameEn,
            });
            this.loading = false;
            return;
          }

          if (this.loginInfo.data.isBlocked) {
            this.generateSnackbar({
              show: true,
              timeout: 3000,
              color: "red",
              text:
                this.currentLanguage == "ru"
                  ? this.string_values.find((x) => x.value == "is_blocked")
                      .nameRu
                  : this.string_values.find((x) => x.value == "is_blocked")
                      .nameEn,
            });
            this.loading = false;
            return;
          }

          if (this.loginInfo.data.expired) {
            this.generateSnackbar({
              show: true,
              timeout: 3000,
              color: "red",
              text:
                this.currentLanguage == "ru"
                  ? this.string_values.find((x) => x.value == "is_expired")
                      .nameRu
                  : this.string_values.find((x) => x.value == "is_expired")
                      .nameEn,
            });
            this.loading = false;
            return;
          }

          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "green",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "success_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "success_message")
                    .nameEn,
          });
          var data = this.$router.resolve({
            path: "/",
          });
          document.location.href = data.href;
        } else {
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "error_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "error_message")
                    .nameEn,
          });
        }
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }

    if (this.loginInfo != null) {
      this.showLoginForm = false;
      this.$router.push({
        path: "/",
      });
    }
  },
};
</script>

<style scoped>
.viortis-background {
  background-color: #1d6d7d;
}
.background {
  height: 100%;
  background-image: url("../../assets/background.jpg");
  background-size: cover;
}
</style>
