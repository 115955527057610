import { render, staticRenderFns } from "./CatalogAction.vue?vue&type=template&id=4dd040be&scoped=true&"
import script from "./CatalogAction.vue?vue&type=script&lang=js&"
export * from "./CatalogAction.vue?vue&type=script&lang=js&"
import style0 from "./CatalogAction.vue?vue&type=style&index=0&id=4dd040be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd040be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VBtnToggle,VIcon,VList,VListItem,VListItemGroup,VListItemTitle,VMenu})
